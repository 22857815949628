<template>
  <div class="logistics-list">
    <div class="list-container">
      <div class="search-container">
        <div class="sc-item">
          <label class="sci-title">代理商</label>

          <el-select v-model="agency" clearable filterable placeholder="请选择">
            <el-option
              v-for="item in agencys"
              :key="item.agency_code"
              :label="item.agency_name"
              :value="item.agency_code"
            >
            </el-option>
          </el-select>
        </div>
        <div class="sc-item">
          <label class="sci-title">充值时间</label>
          <el-date-picker
            v-model="paymentTime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </div>
        <div class="sc-item">
          <label class="sci-title">状态</label>
          <el-checkbox-group v-model="limitState">
            <el-checkbox v-for="s in state" :label="s.key" :key="s.key">{{
              s.value
            }}</el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="sc-item">
          <el-button @click="handleSearch" icon="el-icon-search" type="primary"
            >查询</el-button
          >
          <el-button @click="handleReset" icon="el-icon-refresh-left"
            >重置</el-button
          >
          <el-button icon="el-icon-plus" type="success" @click="handleAdd"
            >新增</el-button
          >
        </div>
      </div>
      <div class="table-container">
        <el-table
          :data="tableData"
          border
          header-cell-class-name="table-header"
        >
          <el-table-column fixed prop="code" label="充值编码">
          </el-table-column>
          <el-table-column prop="agencyName" label="代理商"> </el-table-column>
          <el-table-column label="状态">
            <template slot-scope="scope">
              <el-tag :type="scope.row.state ? 'success' : 'info'">{{
                scope.row.state ? '有效' : '失效'
              }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="qty" label="充值数量"> </el-table-column>
          <el-table-column prop="qtyuse" label="使用数量"> </el-table-column>
          <el-table-column label="过期时间">
            <template slot-scope="scope">
              {{ scope.row.expirationtime | dateFormat('YYYY-MM-DD') }}
            </template></el-table-column
          >
          <el-table-column label="充值时间">
            <template slot-scope="scope">
              {{ scope.row.createtime | dateFormat('YYYY-MM-DD') }}
            </template>
          </el-table-column>
          <el-table-column prop="remark" label="备注"> </el-table-column>
          <el-table-column fixed="right" label="操作">
            <template slot-scope="scope">
              <el-button @click="handleEdit(scope.row)" type="text" size="small"
                >查看</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="page-container">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="pageSizes"
        :page-size="pageSize"
        :layout="pageLayout"
        :total="tableTotal"
      >
      </el-pagination>
    </div>
    <waybill-limit-detail
      @FUNCLOSELIMIT="handleCloseLimitDetail"
      :limitModel="detailModel"
      v-if="showWaybillLimit"
    ></waybill-limit-detail>
  </div>
</template>

<script>
import { PAGESIZELIST, PAGE_LAYOUT, PAGESIZE } from 'config/index'
import WaybillLimit from 'api/waybill-limit'
import commonApi from 'api/common'
import waybillLimitDetail from 'components/waybill-limit-detail'
export default {
  components: { waybillLimitDetail },
  name: '',
  data() {
    return {
      currentPage: 1,
      pageSize: PAGESIZE,
      pageLayout: PAGE_LAYOUT,
      pageSizes: PAGESIZELIST,
      showWaybillLimit: false, // 新增编辑运单充值
      agency: '', // 搜索代理商
      paymentTime: '', // 搜索缴费时间
      limitState: [], // 搜索状态
      state: [
        { key: 1, value: '启用' },
        { key: 0, value: '停用' }
      ],
      detailModel: {},
      tableData: [],
      tableTotal: 0,
      agencys: [] // 代理商
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.getAgencys()
  },
  destroyed() {},
  methods: {
    // 关闭
    handleCloseLimitDetail(value) {
      console.log('handle funCloseLimit')
      this.detailModel = {}
      this.showWaybillLimit = false
      if (value) {
        this.currentPage = 1
        this.tableData = []
        this.getLimits()
      }
    },
    // 新增记录
    handleAdd() {
      this.showWaybillLimit = true
    },
    // 编辑
    handleEdit(row) {
      this.detailModel = JSON.parse(JSON.stringify(row))
      this.showWaybillLimit = true
      console.log(row)
    },
    // 查询
    handleSearch() {
      this.currentPage = 1
      this.tableData = []
      this.getLimits()
    },
    // 重置
    handleReset() {
      this.currentPage = 1
      this.agency = ''
      this.paymentTime = ''
      this.limitState = []
      this.tableData = []
      this.getLimits()
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.currentPage = 1
      this.pageSize = val
      this.tableData = []
      this.getLimits()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.currentPage = val
      this.tableData = []
      this.getLimits()
    },
    // 获取代理商
    getAgencys() {
      commonApi.getAgencys({}, false).then((res) => {
        if (res.success) {
          this.agencys = res.record
          this.getLimits()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    getLimits() {
      let _start = '',
        _end = ''
      if (this.paymentTime) {
        _start = this.paymentTime[0]
        _end = this.paymentTime[1]
      }
      WaybillLimit.getWaybillLimits({
        agencycode: this.agency,
        start: _start,
        end: _end,
        state: this.limitState.join(','),
        curr: this.currentPage,
        pagesize: this.pageSize
      }).then((res) => {
        this.tableData = res.record
        this.tableTotal = +res.message
        if (res.success) {
        } else {
          this.$message.error(res.message)
        }
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
