<!-- 运单充值 -->
<template>
  <div>
    <el-dialog
      title=""
      :visible="true"
      width="550px"
      :before-close="handleClose"
    >
      <div class="hoho-form-container">
        <el-form
          :model="limitModel"
          label-width="120px"
          :rules="rules"
          ref="limitForm"
        >
          <el-form-item label="代理商" prop="agencycode">
            <el-select
              v-model="limitModel.agencycode"
              filterable
              placeholder="请选择代理商"
            >
              <el-option
                v-for="item in agencys"
                :key="item.agency_code"
                :label="item.agency_name"
                :value="item.agency_code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="购买数量" prop="qty">
            <el-input
              v-model="limitModel.qty"
              placeholder="请输入购买数量"
            ></el-input>
          </el-form-item>
          <el-form-item label="过期时间" prop="expirationtime">
            <el-date-picker
              v-model="limitModel.expirationtime"
              type="date"
              placeholder="请选择过期时间"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="启用状态">
            <el-switch
              v-model="limitModel.state"
              :active-value="1"
              :inactive-value="0"
            ></el-switch>
          </el-form-item>
          <el-form-item label="金额" prop="payfee">
            <el-input v-model="limitModel.payfee"></el-input>
          </el-form-item>
          <el-form-item label="凭证">
            <element-upload
              @setpicture="handleSetPricture"
              @delpicture="handleDelPricture"
              :imgUrl="limitModel.proofimgs"
              :images="[limitModel.proofimgs]"
            ></element-upload>
          </el-form-item>
          <el-form-item label="备注">
            <el-input
              type="textarea"
              autosize
              placeholder="请输入内容"
              v-model="limitModel.remark"
            >
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { vxRule, rules } from '@/utils/validator'
import ElementUpload from './element-upload.vue'
import WaybillLimit from 'api/waybill-limit'
import commonApi from 'api/common'
export default {
  components: { ElementUpload },
  name: '',
  props: {
    limitModel: {
      type: Object,
      default: function () {
        return {
          id: '',
          code: '',
          agencycode: '',
          qty: '',
          expirationtime: '',
          state: 0,
          payfee: '',
          proofimgs: '',
          remark: ''
        }
      }
    }
  },
  data() {
    let verifyPayFee = (rule, value, callback) => {
      if (value) {
        let { result, errMsg } = rules.Number(value)
        if (!result) {
          callback()
        } else {
          callback(new Error(errMsg))
        }
      } else {
        callback()
      }
    }
    return {
      rules: {
        agencycode: vxRule(true, null, 'change', '请选择代理商'),
        qty: vxRule(true, 'Number', 'blur', '请输入购买数量'),
        expirationtime: vxRule(true, null, '', '请选择过期时间'),
        payfee: vxRule(false, 'Number', 'blur', '金额只能是纯数字')
      },
      agencys: [] // 代理商
    }
  },
  computed: {},
  watch: {},
  created() {
    console.log('model:', this.limitModel)
    if (!this.limitModel.id) {
      this.addYear()
    }
    this.getAgencys()
  },
  mounted() {},
  destroyed() {},
  methods: {
    addYear() {
      this.limitModel.expirationtime = String(
        this.$dayjs().year(new Date().getFullYear() + 1)
      )
    },
    // 关闭
    handleClose() {
      this.$emit('FUNCLOSELIMIT')
    },
    // 提交
    handleSubmit() {
      this.$refs.limitForm.validate((valid) => {
        if (valid) {
          WaybillLimit.setWaybillLimit({ ...this.limitModel }).then((res) => {
            if (res.success) {
              this.$emit('FUNCLOSELIMIT', true)
            } else {
              this.$message.error(res.message)
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 删除图片
    handleDelPricture(img) {
      this.limitModel.proofimgs = ''
    },
    // 上传图片赋值
    handleSetPricture(imgpath) {
      console.log(imgpath)
      this.limitModel.proofimgs = imgpath
    },
    // 获取代理商
    getAgencys() {
      commonApi.getAgencys({}, false).then((res) => {
        if (res.success) {
          this.agencys = res.record
        } else {
          this.$message.error(res.message)
        }
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
::v-deep {
  .el-input,
  .el-textarea {
    width: 300px;
  }
  .el-upload-dragger {
    width: 300px;
  }
  .preview-images {
    width: 300px !important;
    margin-left: -300px !important;
  }
  .el-image {
    width: 100px !important;
    height: 100px !important;
  }
  .el-upload__tip {
    line-height: 0;
  }
}
</style>
